<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Buku Besar</h2>
            </CCardHeader>
            <CCardBody>
                <div>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">COA</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Coa" class="pb-3" :options="CoaData" v-model="Coa"/>
                            <label id="errorCoa" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateFrom"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Akhir</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateTo"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-12">
                            <button id="view" class="btn btn-success" style="float:right"
                                @click="searchClick()">
                                <i class="fa fa-eye"></i> Search
                            </button>
                        </div>
                    </CRow>
                    <hr>
                    
                    <div class="p-3 border rounded" style="width: 100%;">
                        
                        <div class="col-xs-6 text-light pb-2">
                            <button id="pdf" class="btn btn-secondary"                   
                            @click="pdfClick()">
                                <i class="fa fa-file-pdf-o"></i> PDF
                            </button>&nbsp;
                            <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                                <i class="fa fa-file-excel-o"></i> Excel
                            </button>
                        </div>

                        <datasource ref="ledgerDataSource" 
                                    :data="this.LedgerGridData" 
                                    :page-size="2000" 
                                    :schema-model-fields="this.LedgerSchemaModel"
                        />

                        <kendo-grid ref="gridItem"
                                    :data-source-ref="'ledgerDataSource'"
                                    :pageable="pageableConfig"
                                    :sortable="true"
                                    :filterable="filterableConfig"
                                    :editable="true"
                                    :resizable="true"
                                    
                        >

                            <kendo-grid-column  :field="'transaction_date'"
                                                :title="'Tanggal'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :template="this.dateFormat"></kendo-grid-column>

                            <kendo-grid-column  :field="'transaction_number'"
                                                :title="'Nomor Bukti'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'contact_name'"
                                                :title="'Nama Kontak'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'desc'"
                                                :title="'Deskripsi'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'debit'"
                                                :title="'Debit'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'credit'"
                                                :title="'Kredit'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>                                                
                                                
                            <kendo-grid-column  :field="'balance'"
                                                :title="'Saldo Awal'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'notes'"
                                                :title="'Catatan'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                        </kendo-grid>
                    </div>

                    <ledger-pdf ref="LedgerPdf" />
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import ledgerServices from '../Ledger/Script/LedgerService.js';
import ledgerPdf from '../../../assets/template/LedgerPdfTemplate.vue';
import { response } from '../../../infrastructure/constant/response';
import { kendo_grid } from '../../../infrastructure/constant/variable.js';
import { sweet_alert } from '../../../infrastructure/constant/variable.js';

export default {
    name: 'Ledger',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'ledger-pdf' : ledgerPdf
    },
    async mounted () {
        var begin = moment(new Date()).format("YYYY-MM-01");
        var end = moment(new Date()).format("YYYY-MM-") + moment().daysInMonth();

        this.DateFrom= begin;
        this.DateTo= end;

        this.CoaData = await ledgerServices.getCOAQuery();
        this.Coa = '';
    },
    data(){
        return{
            DateFrom: '',
            DateTo: '',
            CoaData: [],
            Coa: '',

            //grid
            LedgerGridData: [],            
            LedgerSchemaModel: {
                id: { type: "string", editable: false },
                transaction_number: { type: "string", editable: false },
                transaction_date: { type: "string", editable: false },
                contact_id: { type: "string", editable: false },
                contact_name: { type: "string", editable: false },
                desc: { type: "string", editable: false },
                credit: { type: "string", editable: false },
                debit: { type: "string", editable: false },
                balance: { type: "string", editable: false },
                notes: { type: "string", editable: false },
            },
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator,
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        dateFormat(e){
            var date = kendo.toString(kendo.parseDate(e.transaction_date, 'yyyy-MM-dd'), 'yyyy-MM-dd');
            return date == null ? "" : date;
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.Coa == null || this.Coa == ''){
                this.errorShow('errorCoa');
                this.Error++;
            }
            if(this.DateFrom == null || this.DateFrom == ''){
                this.errorShow('errorDateFrom');
                this.Error++;
            }
            if(this.DateTo == null || this.DateTo == ''){
                this.errorShow('errorDateTo');
                this.Error++;
            }
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
                this.Error++;
            }
        },
        async searchClick(){
            this.inputValidation();

            if(this.Error == 0) {
                const variables = {
                    id : this.Coa.value.toString(),
                    startDate: this.DateFrom,
                    endDate: this.DateTo
                }
                var data = await ledgerServices.getLedgerData(variables);
                this.LedgerGridData = data;
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        pdfClick() {
            // var gridData = this.$refs.gridItem.kendoWidget()._data;

            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.LedgerPdf.generatePDF(gridData);
            //     }else if (result.isDenied) {
            this.inputValidation();

            if(this.Error == 0) {
                var id = this.Coa.value.toString();
                var startDate = this.DateFrom;
                var endDate = this.DateTo;

                const routeData = this.$router.resolve({name: 'Ledger Html Template', params: { id:id, startdate:startDate, enddate:endDate }});
                window.open(routeData.href, '_blank');
            }
            //     }
            // });
        },
        async exportExcel() {
            const variables = {
                id : this.Coa.value.toString(),
                startDate: this.DateFrom,
                endDate: this.DateTo
            }
            // var gridData = await ledgerServices.getLedgerData(variables);
            var gridData = this.$refs.gridItem.kendoWidget()._data;
            var data = ledgerServices.balanceExcel(gridData, variables);
            
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'BukuBesar_' + dateNow + '.xlsx';
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = ledgerServices.balanceExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
    }
}
</script>

<style scoped>
</style>