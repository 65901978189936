<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Chart of Accounting</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <!-- <coa-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" /> -->
                <coa-grid-tree :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" />
                <coa-form ref="CoaForm" :reload="reload"/>
                <!-- <item-form ref="itemDataForm" :saveMutasiStockItemGrid="saveMutasiStockItemGrid"/> -->
            </CCardBody>
        </CCard>
    </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue-numeral-filter/dist/vue-numeral-filter.min.js"></script>

<script>
// import MutasiStockService from '../MutasiStock/Script/MutasiStockServices.js';
import CoaGrid from '../COA/Grid/CoaGrid.vue';
import CoaGridTree from '../COA/Grid/CoaGridTree.vue'
import CoaForm from '../COA/Component/CoaForm.vue';
import CoaServices from './Script/CoaService';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

// import ItemDataForm from '../MutasiStock/Component/ItemDataForm.vue';

export default {
    name: 'COA',
    components: {
        'coa-grid': CoaGrid,
        'coa-grid-tree': CoaGridTree,
        'coa-form': CoaForm,
        // 'item-form' : ItemDataForm,
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('COA');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.CoaForm.addClick();
        },
        editClick(coaData, view){
            this.$refs.CoaForm.editClick(coaData, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id.toString()
                }
                if (result.isConfirmed == true) {
                    CoaServices.deleteCoa(variables).then(res =>{
                        this.$swal("Info", response.successDelete, "success");
                        this.reload();
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
        
    }
}
</script>

<style scoped>
</style>