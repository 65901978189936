<template>
    <div>
        <div class="modal fade" id="settingBalanceModal" tabindex="-1" aria-labelledby="settingBalanceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="settingBalanceModalLabel" class="font-weight-bold">Simpan Pengaturan Baru</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="5">
                                <label class="form-label font-weight-bold required">Nama Pengaturan Baru</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="7">
                                <CInput id="SettingName" v-model="SettingName" class="font-weight-bold" />
                                <label id="errorSettingName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>

                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import trialBalanceServices from '../Script/TrialBalanceServices';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'SettingBalanceForm',
    components: {
    },
    props: ['reloadSettingDDL'],
    data: function () {
        return {
            SettingName: '',
            Error: 0,
            SaveType: '',

            // Grid
            DetailGrid: [],
        }
    },
    methods: {
        addClick(gridData){   
            $(".form-error").css("display", "none");  

            this.SettingName = '';
            this.DetailGrid = gridData;

            window.$('#settingBalanceModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.SettingName == ''){
                this.errorShow('errorSettingName');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                const settingData = {
                    name : this.SettingName,
                    balance_setting_detail : this.DetailGrid
                }

                const variables = {
                    data : settingData
                }
                
                trialBalanceServices.addSetting(variables).then(res => {
                    this.$swal("Info", response.successSave, "success");
                    window.$('#settingBalanceModal').modal('hide');
                    this.$props.reloadSettingDDL();
                }).catch(error => {
                    var errorMessage = globalfunc.errorExtractor(error);
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
